<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="activity_create_back">
      <el-button type="text" @click="handleBack">
        <i class="el-icon-arrow-left"></i>返回游玩项目列表</el-button
      >
      <div>
        <el-button
          type="primary"
          @click="handleClick('edit')"
          style="width: 150px"
          >编辑游玩项目</el-button
        >
        <el-button
          type="primary"
          v-if="detailData.isVisible"
          @click="handleClick('down')"
          >下 架</el-button
        >
        <el-button
          type="primary"
          v-else-if="!detailData.isVisible"
          @click="handleClick('up')"
          >上 架</el-button
        >
      </div>
    </div>

    <div class="activity_create_bg">
      <h4>{{ detailData.goodsName }}</h4>
      <p style="margin-left: 0; margin-top: 10px">
        位于{{ detailData.goodsLocationName }}
      </p>
    </div>

    <div class="activity_create_bg">
      <el-calendar v-model="value">
        <template slot="dateCell" slot-scope="{ date, data }">
          <div @click="handleDateCell(data.day)">
            {{ data.day.split("-").slice(2).join("-") }}
          </div>
          <div v-for="item in scheduleData" :key="item.goods_date">
            <p
              class="calendar_text"
              v-if="item.goods_date.indexOf(data.day) != -1"
            >
              {{ item.dailyOrderCount == 0 ? "" : item.dailyOrderCount }}
            </p>
          </div>
        </template>
      </el-calendar>

      <el-table :data="tableData" border>
        <el-table-column label="预定总量" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOrderList('orderTotal', row)">
              {{ orderTotal }}<i class="el-icon-arrow-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="" label="当日预定" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleOrderList('dailyOrderCount', row)"
            >
              {{ row.dailyOrderCount }}<i class="el-icon-arrow-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="dailyStoke" label="当日库存" align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="activity_create_bg">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <h4>销售规格</h4>
      </div>
      <el-table :data="tableDataHouseType" border style="width: 100%">
        <el-table-column
          :label="item.specKey"
          v-for="item in columnKeyList"
          :key="item.id"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[scope.column.property] }}</span>
            <span>{{ scope.row.specInfo.specAttr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :required="true"
          label="默认价格"
          width="420"
        >
          <template slot-scope="{ row, $index }">
            ${{ row.specInfo.basePrice }}
            <div class="create_table">
              <div>
                <p>A级专属价</p>
                <p>{{ (row.specInfo.aDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>B级专属价</p>

                <p>{{ (row.specInfo.bDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>C级专属价</p>
                <p>{{ (row.specInfo.cDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>D级专属价</p>
                <p>{{ (row.specInfo.dDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="当日库存(仅对当日有效)"
          width="200"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.dailyStoke }} <br />
            <el-button type="text" @click="handleEditSkock(row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="当日预定" width="110" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOrderList2(row)">
              {{ row.dailyOrderCount }}
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import { format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
  },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "游玩项目发布管理",
        },
        {
          name: "项目详情",
        },
      ], // 面包屑数据

      tableData: [],
      tableDataHouseType: [], // 房型信息表格

      detailData: {},
      orderTotal: "",

      value: new Date(),
      newOldValue: "",
      scheduleData: [],
      columnKeyList: [],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldGetWorldSightseeingDetail,
      method: "POST",
      params: JSON.stringify({
        sightseeingId: this.$route.query.sightseeingId,
        goodsDate: this.newOldValue || format_time_date_noTime(this.value),
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.detailData = res.data.data.sightInfoDTO;
          this.tableDataHouseType = res.data.data.skuDailyVOS;
          this.columnKeyList = res.data.data.keyList;

          this.scheduleData = res.data.data.sightseeingDailyVOS;
          this.scheduleData.map((item) => {
            item.goods_date = format_time_date_noTime(item.goods_date);

            let newValFormat = format_time_date_noTime(this.value);
            if (item.goods_date == newValFormat) {
              this.tableData.push(item);
            }
          });

          this.orderTotal = res.data.data.orderTotal;

          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  watch: {
    value(newVal, oldVal) {
      // 这里就是我们处理数据，调用接口的位置
      this.handleDateCell(newVal);
      this.newOldValue = newVal;
    },
  },

  methods: {
    handleBack() {
      this.$router.push("/worldPlayList");
    },

    handleDateCell(newVal) {
      const opt = {
        url: reqUrl.worldGetWorldSightseeingDetail,
        method: "POST",
        params: JSON.stringify({
          sightseeingId: this.$route.query.sightseeingId,
          goodsDate: format_time_date_noTime(newVal),
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            console.log(res.data, "111");
            this.detailData = res.data.data.sightInfoDTO;
            this.tableDataHouseType = res.data.data.skuDailyVOS;
            this.scheduleData = res.data.data.sightseeingDailyVOS;

            res.data.data.sightseeingDailyVOS.map((item, index) => {
              item.goods_date = format_time_date_noTime(item.goods_date);

              let newValFormat = format_time_date_noTime(newVal);
              if (item.goods_date == newValFormat) {
                this.tableData[0] = item;
                this.$set(this.tableData, 0, item);
              }
            });

            this.orderTotal = res.data.data.orderTotal;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(opt);
    },

    handleOrderList(type, row) {
      this.$router.push({
        path: "/worldPlayOrderListDetail",
        query: {
          type: type,
          orderTotal: type == "orderTotal" ? this.orderTotal : null,
          dailyOrderCount:
            type == "dailyOrderCount" ? row.dailyOrderCount : null,
          sightseeingId: this.$route.query.sightseeingId,
          goodsDate: format_time_date_noTime(this.value),
        },
      });
    },

    handleOrderList2(row) {
      this.$router.push({
        path: "/worldPlayOrderListDetail",
        query: {
          type: 3,
          sightseeingId: this.$route.query.sightseeingId,
          specId: row.specId,
          goodsDate: format_time_date_noTime(this.value),
          orderTotal: row.dailyOrderCount,
        },
      });
    },

    handleClick(type) {
      if (type == "edit") {
        this.$router.push({
          path: "/worldPlayListEdit",
          query: {
            type: "edit",
            sightseeingId: this.$route.query.sightseeingId,
          },
        });
      }
      if (type == "down" || type == "up") {
        this.$confirm("您确定要进入此操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.worldSightseeingOnSale,
            method: "POST",
            params: JSON.stringify({
              sightseeingId: this.$route.query.sightseeingId,
              isVisible: type == "down" ? false : true,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                if (type == "down") {
                  this.$message({
                    message: "下架成功",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: "上架成功",
                    type: "success",
                  });
                }

                this.handleBack();
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
            },
          };
          request(opt);
        });
      }
    },

    handleEditSkock(row) {
      this.$prompt("请输入库存", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.+$/,
        inputErrorMessage: "请输入库存",
      }).then(({ value }) => {
        showLoading();
        const opt = {
          url: reqUrl.worldUpdateSightseeingSkuStock,
          method: "POST",
          params: JSON.stringify({
            specId: row.specId,
            goodsDate: row.goodsDate,
            stock: value,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.refresh();
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
          },
        };
        request(opt);
      });
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
</style>
